import { Link, useLocation } from 'react-router-dom';
import React from 'react';

interface HeaderProps {
  pathImage: string;
}

function Header({ pathImage }: HeaderProps) {
  const location = useLocation();

  const activeLinkStyle =
    'text-secondary font-bold underline lg:before:content-["•"] lg:before:text-secondary lg:before:absolute lg:before:-left-6 lg:translate-x-10 xl:before:-left-6 xl:translate-x-10 2xl:before:-left-6 2xl:translate-x-10';
  const regularLinkStyle =
    'text-white hover:bg-zinc-800 rounded-full transition-all duration-300 pr-5 pl-5 sm:pr-5 sm:pl-5 md:pr-5 md:pl-5 lg:pr-5 lg:pl-2 xl:pr-5 xl:pl-5 2xl:pr-5 2xl:pl-5';

  return (
    <div className="w-[100%] sticky flex flex-col lg:flex-row items-center space-y-1 sm:space-y-4 lg:space-y-0 justify-center h-full lg:h-screen py-10 pb-5 sm:pb-10 space-x-0 lg:py-0 lg:items-center lg:w-[45%] lg:space-x-4 xl:w-[50%] xl:space-x-6 2xl:w-[50%] 2xl:space-x-16">
      {/* Image + Name */}
      <div className="flex flex-col justify-center items-center w-[35%] sm:w-[35%] md:w-[40%] lg:w-[55%] xl:w-[50%] 2xl:w-[50%]">
        <div className="w-full flex flex-col items-center space-y-1 sm:space-y-2 md:space-y-2 lg:space-y-3 xl:space-y-4 2xl:space-y-5">
          <img
            src={pathImage}
            alt="logo"
            className="rounded-t-[10px] border-black border-2 drop-shadow-myShadowBox w-full object-cover h-[600px] sm:h-[600px] md:h-[600px] lg:h-[650px] xl:h-[650px] 2xl:h-[750px]"
          />
          <div className="font-black text-white w-full text-center text-lg sm:text-2xl md:text-3xl lg:text-3xl xl:text-4xl 2xl:text-5xl">
            JASON LAGOUTE
          </div>
        </div>
      </div>
      {/* NavBar */}
      <div className="flex lg:flex-col justify-around items-start text-lg sm:text-xl md:text-2xl h-full w-full lg:text-xl lg:h-[50%] lg:w-[35%] xl:text-2xl xl:h-[50%] xl:w-[30%] 2xl:text-3xl 2xl:h-[50%] 2xl:w-[35%]">
        <Link
          to="/projects"
          className={`${location.pathname === '/projects' ? activeLinkStyle : regularLinkStyle}`}>
          Mes projets
        </Link>
        <Link
          to="/profil"
          className={`${
            location.pathname === '/profil' || location.pathname === '/'
              ? activeLinkStyle
              : regularLinkStyle
          }`}>
          Mon profil
        </Link>
        <Link
          to="/contact"
          className={`${location.pathname === '/contact' ? activeLinkStyle : regularLinkStyle}`}>
          Me contacter
        </Link>
      </div>
    </div>
  );
}

export default Header;
