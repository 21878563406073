import React from 'react';
import Header from '../Components/Header';
import { Link } from 'react-router-dom';

function ProfilPage() {
  return (
    <div className="flex flex-col lg:flex-row w-full min-h-screen">
      <Header pathImage="meProfil.jpg" />
      <div className="text-white pb-10 h-full lg:pb-0 lg:min-h-screen justify-center items-center flex flex-col w-[100%] text-xs sm:text-sm md:text-3xl lg:justify-center lg:items-start lg:text-sm lg:w-[50%] xl:text-base xl:w-[50%] 2xl:text-lg 2xl:w-[50%]">
        <div className="bg-primary bg-opacity-20  rounded-[10px] text-justify p-4 space-y-4 w-[90%] sm:p-8 sm:space-y-2 sm:w-[90%] md:p-8 md:space-y-2 md:w-[90%] lg:p-6 lg:space-y-2 lg:w-[100%] xl:p-10 xl:space-y-4 xl:w-[90%] 2xl:p-10 2xl:space-y-4 2xl:w-[90%]">
          <h1 className="text-left font-bold drop-shadow-title text-xl pb-0 sm:text-3xl sm:pb-2 md:text-5xl md:pb-4 lg:text-4xl lg:pb-4 xl:text-5xl xl:pb-4 2xl:text-6xl 2xl:pb-4">
            À PROPOS DE MOI
          </h1>
          <p>
            &emsp;Je suis un développeur de 21 ans basé sur Rennes en France. Passionné par
            l&apos;informatique depuis 2019, j&apos;ai décidé de rejoindre Epitech Rennes en 2021
            pour faire de ma passion mon métier. Au sein d&apos;Epitech ainsi que par des projets
            personnels, j&apos;ai pu acquérir des compétences solides en programmation mais aussi en
            travail d&apos;équipe ou encore en soft skills.
          </p>
          <p>
            &emsp;Je suis une personne de nature très curieuse et qui a soif d&apos;apprendre.
            J&apos;ai donc pu toucher à beaucoup de domaines : le jeux vidéo, le DevOps, la
            cybersécurité, le réseau, le web, le mobile…etc.
          </p>
          <p>
            &emsp;Je souhaite donc mettre mes compétences en pratique, c&apos;est pour cela que je
            suis aujourd&apos;hui en freelance. Je ne me spécialise pas dans un domaine particulier,
            je peux donc aller d&apos;un développement d&apos;un nouveau projet applicatif
            jusqu&apos;à de l&apos;ajout de fonctionnalités pour quelque chose d&apos;existant. Par
            des missions précédentes, j&apos;ai appris également à utiliser et développer des
            fonctionnalités pour certains CMS connus comme WordPress, PrestaShop ou encore Shopify.
          </p>
          <p>
            &emsp;Vous trouverez des exemples de projets réalisés sur la page{' '}
            <span className="italic">
              <Link to="/projects">Mes projets</Link>
            </span>{' '}
            et vous trouverez également mon CV en cliquant sur le bouton en bas à droite.
          </p>
          <p>
            Si vous souhaitez me contacter, je vous prie de consulter la page{' '}
            <span className="italic">
              <Link to="/contact">Me contacter</Link>
            </span>
            .
          </p>
        </div>
      </div>
    </div>
  );
}

export default ProfilPage;
