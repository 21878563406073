import Header from '../Components/Header';
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

function ContactPage() {
  const form = useRef<HTMLFormElement>(null);

  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (form.current) {
      emailjs
        .sendForm(
          process.env.REACT_APP_SERVICE_ID_CONTACT || '',
          process.env.REACT_APP_TEMPLATE_ID_CONTACT || '',
          form.current,
          process.env.REACT_APP_PUBLIC_KEY_CONTACT || ''
        )
        .then(
          (result) => {
            console.log('SUCCESS!', result.text);
            form.current?.reset();
          },
          (error) => {
            console.log('FAILED...', error);
          }
        );
    }
  };

  const inputStyle =
    'rounded-[10px] border-black drop-shadow-myShadowBox bg-tertiary px-4 py-2 w-[48%] sm:px-4 sm:py-3 sm:w-[48%] md:px-4 md:py-4 md:w-[48%] lg:px-4 lg:py-2 lg:w-[48%] xl:px-4 xl:py-3 xl:w-[48%] xl:text-base 2xl:px-4 2xl:py-4 2xl:w-[48%]';

  return (
    <div className="flex flex-col lg:flex-row w-full">
      <Header pathImage="meContact.jpg" />
      <div className="text-white h-full pb-10 lg:pb-0 lg:min-h-screen justify-start items-center flex flex-col w-[100%] text-xs sm:text-sm md:text-3xl lg:justify-center lg:items-start lg:text-sm lg:w-[50%] xl:text-base xl:w-[50%] 2xl:text-lg 2xl:w-[50%]">
        <div className="bg-primary bg-opacity-20 rounded-[10px] text-justify p-4 space-y-4 w-[90%] sm:p-8 sm:space-y-2 sm:w-[90%] md:p-8 md:space-y-2 md:w-[90%] lg:p-6 lg:space-y-2 lg:w-[100%] xl:p-10 xl:space-y-4 xl:w-[90%] 2xl:p-10 2xl:space-y-4 2xl:w-[90%]">
          <h1 className="text-left font-bold drop-shadow-title text-xl pb-0 sm:text-3xl sm:pb-2 md:text-5xl md:pb-4 lg:text-4xl lg:pb-4 xl:text-5xl xl:pb-4 2xl:text-6xl 2xl:pb-4">
            CONTACTE-MOI !
          </h1>
          <form
            ref={form}
            className="flex flex-col text-black space-y-2 sm:space-y-2 md:space-y-4 lg:space-y-2 xl:space-y-4 2xl:space-y-4"
            onSubmit={sendEmail}>
            <div className="flex justify-between">
              <input
                name="user_firstname"
                placeholder="Prénom"
                type="text"
                maxLength={50}
                required
                className={inputStyle}
              />
              <input
                name="user_name"
                placeholder="Nom"
                type="text"
                maxLength={50}
                required
                className={inputStyle}
              />
            </div>
            <div className="flex justify-between">
              <input
                name="user_email"
                placeholder="Email"
                type="email"
                maxLength={100}
                required
                className={inputStyle}
              />
              <input
                name="user_number"
                placeholder="Téléphone"
                type="tel"
                pattern="[0-9]*"
                maxLength={20}
                required
                className={inputStyle}
              />
            </div>
            <textarea
              name="message"
              rows={6}
              maxLength={3600}
              required
              placeholder="Message"
              className="rounded-[10px] border-black drop-shadow-myShadowBox bg-tertiary p-4"></textarea>
            <button
              type="submit"
              className="bg-secondary rounded-[10px] drop-shadow-xl shadow-black font-bold py-2">
              Envoyer
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactPage;
